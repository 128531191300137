@tailwind base;
@tailwind components;
@tailwind utilities;

/* TOC markup is created by Kramdown and cannot be styled inline */
@layer components {
    ul#markdown-toc li a {
        @apply inline-block w-full h-full text-secondary-600 hover:bg-neutral-50 hover:text-secondary-900 px-3 py-2 text-sm font-medium rounded-md transition aria-current-location:text-secondary-900 aria-current-location:bg-neutral-100;
    }
    ul#markdown-toc ul {
        @apply pl-7;
    }
}
